function displayLoading() {
    $('#loading').removeClass('hidden').fadeIn();
    $('#output').removeClass('visible').addClass('hidden');
  }
  
  function hideLoading() {
    $('#loading').removeClass('visible').fadeOut();
  }
  
  function displayOutput(output) {
    $('#output').html(output).removeClass('hidden').addClass('visible'); // Ensure HTML is rendered
  }
  
  function displayError(error) {
    $('#output').text(`Error: ${error.message}`).removeClass('hidden').addClass('visible');
  }
  
  async function sendPrompt(event) {
    event.preventDefault();

    const prompt = $('#prompt').val();
    const urls = await fetchSitemap();
    const urlsString = urls.join('\n');

    const fullPrompt = `I need your help, you are my assistant on helping me find out which shipping container size I need based on what I am storing. I am storing ${prompt}. In your response, please say that "We Think" like you work for my company. Please only suggest a container size & type (Standard container, High Cube Container, Open Side Container, Tunnel Container) for my item please also explain why its the best choice, in 60 words, then also use perhaps 20 words for a tip regarding the storage of my item. Do not write "Tip:" before this. I want it all one paragraph. wrap the whole paragraph in a <p> so it is not inline with the button from the following instruction. And then choose the most releavent page from this list and format it as an <a> with the class="button" and the button text the page title: \n${urlsString}`;

    console.log("Full Prompt:", fullPrompt);
    MicroModal.show('ai');
    displayLoading();

    try {
        const response = await fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer sk-proj-JNu9D9zdav2a3T9xOZmPT3BlbkFJJbuyOQ0EiSSs6cHJTP7H'
            },
            body: JSON.stringify({
                model: "gpt-4",
                messages: [{"role": "system", "content": "You are a helpful assistant."}, {"role": "user", "content": fullPrompt}],
                max_tokens: 150
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        let output = data.choices[0].message.content;

        console.log("API Response:", data);

        
        displayOutput(output);
        $(".modal__content-inner").fadeIn();
        hideLoading();

        // Call any additional actions on successful request here
        // additionalActions();

    } catch (error) {
        console.error("Error during API request:", error);
        displayError(error);
        hideLoading();
    }
  }
  
  async function findRelevantPage(prompt) {
    const urls = await fetchSitemap();
    console.log("Prompt for finding relevant page:", prompt); // Debugging line
    // Simple relevance check: find the first URL that contains the prompt keyword
    const relevantUrl = urls.find(url => url.toLowerCase().includes(prompt.toLowerCase()));
    console.log("Relevant URL found:", relevantUrl); // Debugging line
    return relevantUrl || null;
  }

  async function fetchSitemap() {
    console.log("Fetching sitemap..."); // Debugging line
    try {
        const response = await fetch('/container-sitemap.xml');
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const sitemapText = await response.text();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(sitemapText, "application/xml");
        const urls = Array.from(xmlDoc.getElementsByTagName('loc')).map(loc => loc.textContent);
        console.log("Fetched URLs from sitemap:", urls); // Debugging line
        return urls;
    } catch (error) {
        console.error("Error fetching sitemap:", error);
        return [];
    }
  }
  
  

$(document).ready(function(){
  document.getElementById('promptForm').addEventListener('submit', sendPrompt);
})  

  


